<!--
 * @Author: lbh
 * @Date: 2021-04-23 11:23:23
 * @LastEditors: lbh
 * @LastEditTime: 2021-09-23 15:47:48
 * @Description: 帶動畫的按鈕
-->
<template>
  <div
    :class="`${type == 'default' ? 's-button' :'s-button_duck'} s-btn_${index}_${v}` "
    class="pointer-hover"
    @mouseover.stop="btnOver(`.s-btn_${index}_${v}`)"
    @mouseout.stop="btnOut(`.s-btn_${index}_${v}`)"
    @click="goUrl(go)"
    :style="style_"
  >
    <span
      class="pointer-hover"
      v-text="text"
    ></span>
    <i
      class="el-icon-right pointer-hover"
      style="color:#FFF"
    ></i>
  </div>
</template>
<script>
import jquery from "jquery";
export default {
  name: 's-button',
  data () {
    return {
      v: 0
    }
  },
  mounted () {
    this.v = new Date().getTime();
  },
  methods: {
    btnOver (t) {
      jquery(`${t} span`).stop();
      jquery(`${t} span`).animate({
        left: '-10px'
      }, {
        speed: 100,
      })
      jquery(`${t} i`).stop();
      jquery(`${t} i`).animate({
        left: '10px'
      }, {
        speed: 100,
      })
    },
    btnOut (t) {
      jquery(`${t} span`).stop();
      jquery(`${t} span`).animate({
        left: '0'
      }, {
        speed: 100,
      })
      jquery(`${t} i`).stop();
      jquery(`${t} i`).animate({
        left: '0'
      }, {
        speed: 100,
      })
    },
    goUrl (t) {
      let pathname = location.pathname;
      if (pathname.indexOf(t) == -1 || (t == '/' && pathname != t)) {
        this.$router.push({
          path: t
        });
      }
    }
  },
  props: {
    index: {
      default: 0
    },
    text: {
      default: ''
    },
    type: {
      default: 'default'
    },
    style_: {
      default: ''
    },
    go: {
      default: () => {
        return ''
      }
    }
  }
}
</script>
<style lang="less" scoped>
.s-button {
  width: 118px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  background-color: #980000;
  border-color: #980000;
  color: #fff;
  border-radius: 5px;
  margin-top: 20px;
  span {
    position: relative;
  }
  i {
    display: none;
    position: relative;
    font-size: 16px;
  }
  &:hover {
    & i {
      display: block;
    }
  }
}

.s-button_duck {
  margin-top: 30px;
  visibility: visible;
  color: rgb(0, 0, 0);
  background-color: transparent;
  border: 2px solid rgba(0, 0, 0, 0.75);
  font-size: 12px;
  height: 40px;
  margin-top: auto;
  border-radius: 5px;
  width: 153px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  span {
    position: relative;
  }
  i {
    display: none;
    position: relative;
    font-size: 20px;
  }
  &:hover {
    background-color: #980000;
    border-color: #980000 !important;
    color: #fff;
    & i {
      display: block;
    }
  }
}
</style>